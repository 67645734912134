import { buttonSecondary, colors, container, h3, h4, mediaQueries, paragraph } from '@theme/lib';
import styled, { css } from 'styled-components';

export const StyledNavigation = styled('nav').withConfig({
  shouldForwardProp: (prop) => prop !== "searchOpen",
})<{ open: boolean; searchOpen: boolean }>`
  width: 100%;
  overflow-y: auto;
  transition: ${(props) => props.searchOpen ? undefined : 'left 0.2s ease-in'};
  position: ${(props) => (props.open ? 'relative' : 'absolute')};
  left: ${(props) => ((props.open || props.searchOpen) ? '0' : '-110%')};
  min-height: calc(100vh - 4.8125rem); /* checked header bar's height */
  ${mediaQueries.mdLogo} {
    position: ${(props) => props.searchOpen ? 'relative': 'initial'};
    width: auto;
    overflow-y: initial;
    min-height: auto;
    grid-column: 2 / span 10;
  }
`;

export const StyledPrimaryNavigation = styled('div').withConfig({
  shouldForwardProp: (prop) => prop !== "searchOpen" && prop !== "isTablet" && prop !== "secondaryNavOpen"
})<{ open: boolean; secondaryNavOpen: boolean; isTablet: boolean; searchOpen: boolean }>`
  border-radius: ${((props) => props.theme.config.componentRadius)};
  transition: ${(props) => props.searchOpen ? undefined : 'left 0.2s ease-in'};
  position: absolute;
  left: ${(props) => ((props.open || props.searchOpen) ? '0' : '-100%')};
  width: 100%;
  display: ${(props) => (props.isTablet && props.secondaryNavOpen ? 'none' : undefined )};
  ${mediaQueries.mdLogo} {
    position: ${(props) => props.searchOpen ? 'relative': 'initial'};
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  > .nav-wrapper {
    padding: 0.8125rem 1rem 1rem;
    background-color: ${(props) =>
      props.isTablet
        ? props.theme.key === 'zsl'
          ? props.theme.colors.primary
          : props.theme.colors.background
        : undefined};
    ${mediaQueries.mdLogo} {
      background: none;
      display: flex;
      padding: 0;
    }
    .nav-items {
      display: flex;
      flex-direction: column;
      ${mediaQueries.mdLogo} {
        flex-direction: row;
        flex-basis: fit-content;
        justify-items: center;
        align-items: center;
      }
      > .nav-item {
        color: ${(props) => (props.theme.key === 'zsl' ? colors.white : colors.black)};
        ${mediaQueries.mdLogo} {
          font-size: ${(props) => (props.theme.key !== 'zsl' ? '1rem' : undefined )};
          color: ${colors.black};
          span {
            font-family: ${(props) => props.theme.fonts.button};
          }
        }
        ${mediaQueries.lgLogo} {
          font-size: 1.125rem;
        }
      }
    }
    hr {
      border: 1px solid ${(props) => (props.theme.key === 'zsl' ? colors.white : colors.honeyBee)};
      margin-bottom: 1rem;
    }
  }
`;

export const StyledSecondaryNavigation = styled('div')<{ open: boolean }>`
  position: absolute;
  left: ${(props) => (props.open ? '0' : '-100%')};
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.theme.key === 'zsl' ? props.theme.colors.primary : props.theme.colors.background};
  box-shadow: 0px 5px 10px -5px rgb(0 0 0 / 40%);
  opacity: ${(props) => (props.open ? undefined : '0')};
  z-index: ${(props) => (props.open ? '2' : '-1')};
  animation: ${(props) => (props.open ? 'fadeIn' : undefined)} 0.5s;
  display: ${(props) => (props.open ? 'block' : 'none')};
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  ${mediaQueries.mdLogo} {
    border-radius: ${((props) => props.theme.config.componentRadius)};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    left: 0;
    top: ${(props) => props.theme.key === 'zsl' ? '6.9rem' : '6.5rem'};
    bottom: unset;
    overflow: hidden;
  }
  .nav-wrapper {
    ${container}
    padding-top: 0.8125rem;
    padding-bottom: 1rem;
    position: relative;
    background-color: ${(props) =>
      props.theme.key === 'zsl' ? props.theme.colors.primary : props.theme.colors.background};
    ${mediaQueries.mdLogo} {
      padding-top: 2.125rem;
      padding-bottom: 1.5rem;
    }
  }
  .nav-secondary {
    &-wrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: 1.5rem;
      justify-content: space-between;
      align-items: center;
    }
    &-title {
      ${h3}
      color: ${(props) => (props.theme.key === 'zsl' ? colors.white : colors.black)};
      position: relative;
      padding-right: 3.3rem;
      display: inline-block;
      &::after {
        content: '';
        width: 33px;
        height: 24px;
        display: block;
        background-color: ${(props) => (props.theme.key === 'zsl' ? props.theme.colors.tertiary : props.theme.colors.primary)};
        mask-image: url('/NavigationItems/arrow.svg');
        mask-repeat: no-repeat;
        mask-size: contain;
        order: 1;
        mask-position: center;
        flex: 0 0 3rem;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
      }
    }
    &-description {
      ${paragraph}
      color: ${(props) => (props.theme.key === 'zsl' ? colors.white : colors.black)};

      ${mediaQueries.mdLogo} {
        margin-left: 1rem;
        margin-right: auto;
        max-width: 80%;
      }
    }
  }
  .nav-secondary-items {
    ${mediaQueries.mdLogo} {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(3, 1fr);
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      justify-items: flex-start;
    }
    ${mediaQueries.navxl} {
      grid-template-columns: repeat(4, 1fr);
    }
    > .nav-item {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1.875rem;
      color: ${(props) => (props.theme.key === 'zsl' ? colors.white : colors.black)};
      span {
        font-family: ${(props) => props.theme.fonts.body};
      }
      ${mediaQueries.mdLogo} {
        ${h4}
        font-style: normal;
        text-transform: none;
        padding: 0 3.55rem 0 0;
        margin-bottom: 1.5rem;
        margin-right: 1.75rem;
        &:hover,
        &:focus-visible {
          text-decoration: underline;
          &::after {
            position: absolute;
            right: 0;
          }
        }
        &::before {
          content: '';
          display: none;
        }
      }
    }
  }
  hr {
    border: 1px solid ${(props) => (props.theme.key === 'zsl' ? colors.white : colors.honeyBee)};
    margin-bottom: 1rem;
    ${mediaQueries.mdLogo} {
      border: 1px solid ${colors.honeyBee};
      margin-bottom: 1.5rem;
    }
  }
`;

const secondaryButton = css`
  ${buttonSecondary}
  font-weight: 400;
  margin-bottom: 1.9375rem;
  position: relative;
  > span {
    display: inline-block;
    max-width: 85%;
  }
  &::before {
    content: '';
    width: 31px;
    height: 16px;
    display: block;
    mask-image: url('/Navigation/secondary-arrow.svg');
    mask-repeat: no-repeat;
    mask-size: contain;
    order: 1;
    mask-position: center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

export const StyledBackButton = styled('button')`
  ${secondaryButton}
  padding: 0.6875rem 0.5rem;
  > span {
    font-family: ${(props) => props.theme.fonts.button};
    margin-left: 3rem;
  }
  &::before {
    left: 1rem;
    background-color: ${(props) => props.theme.colors.primary };
  }
`;

export const StyledSecondaryLink = styled('a')`
  ${secondaryButton}
  &.secondary-cta {
    white-space: normal;
    text-transform: none;
    padding: 1.75rem;
    display: block;
    background-color: ${(props) => props.theme.key === 'zsl' ? undefined : props.theme.colors.footerBgr };
    color: ${(props) => props.theme.key === 'zsl' ? colors.black: colors.white};
    font-size: 1.25rem;
    line-height: 120%;
    text-align: left;
    margin-bottom: 1rem;
    > span {
      font-family: ${(props) => props.theme.fonts.body};
      font-weight: 700;
    }
    &::before {
      right: 1.75rem;
      transform: translate(0, -50%) rotate(180deg);
      background-color: ${(props) => props.theme.key === 'zsl' ? props.theme.colors.primary : colors.honeyBee };
    }
  }
`;

export const StyledBottomCtas = styled('div')<{ open: boolean }>`
  .nav-ctas-wrapper {
    padding-bottom: 4rem;
    ${mediaQueries.mdLogo} {
      padding: 1.7rem 0 1.7rem 1.25rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-basis: initial;
      border-left: 1px solid ${colors.zooBg};
      margin-top: -${(props) => (props.open ? '0.875rem' : '0.625rem')};
      margin-bottom: -${(props) => (props.open ? '0.875rem' : '0.625rem')};
    }
    ${mediaQueries.xl} {
      padding-left: 2rem;
    }
    > a {
      display: block;
      margin-bottom: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-radius: ${(props) => props.theme.key !== 'zsl' ? '8px' : undefined};
      border-width: 1px;
      border-color: ${(props) => props.theme.key === 'zsl' ? 'transparent' : props.theme.colors.primary };
      background-color: ${(props) => props.theme.key === 'zsl' ? colors.zooBg : colors.white };
      &:hover {
        border-color: ${(props) => props.theme.key === 'zsl' ? colors.parakeet : colors.honeyBee };
      }
      ${mediaQueries.mdLogo} {
        margin-right: 0.5rem;
        font-family: ${(props) => props.theme.fonts.button};
        font-style: normal;
        font-weight: 400;
        font-size: ${(props) => (props.theme.key !== 'zsl' ? '1rem' : undefined )};
        line-height: 130%;
        text-align: center;
        text-transform: uppercase;
        color: ${colors.black};
        display: inline-block;
        text-decoration: none;
        background-color: ${(props) => props.theme.key === 'zsl' ? colors.zooBg : props.theme.colors.background };
        border: ${(props) => props.theme.key === 'zsl' ? '2px solid transparent' : `1px solid ${props.theme.colors.primary}` };
        padding: 0.5rem;
        margin-bottom: 0;
        &:hover {
          border-color: ${(props) => props.theme.key === 'zsl' ? colors.seagrass : undefined };
        }
      }
      ${mediaQueries.lgLogo} {
        font-size: 1.125rem;
      }
      ${mediaQueries.xl} {
        margin-right: 1.75rem;
      }
    }
  }
`;
