import { colors, grid, mediaQueries } from '@theme/lib';
import styled from 'styled-components';

export const StyledHeader = styled('header').withConfig({
  shouldForwardProp: (prop) => prop !== "secondaryNavOpen" && prop !== "isTablet"
})<{
  open: boolean;
  secondaryNavOpen: boolean;
  isTablet: boolean;
}>`
  /* Avoid flickering effect of the hover animation
   on the menu elements on first load */
  &.preload *::before {
    animation-duration: 0s;
    transition: all 0s;
  }
  &.mobile {
    opacity: 1;
    transition: opacity 0.1s;
    ${mediaQueries.mdLogo} {
      opacity: 0;
    }
  }
  &.desktop {
    opacity: 0;
    transition: opacity 0.1s;
    ${mediaQueries.mdLogo} {
      opacity: 1;
    }
  }
  ${mediaQueries.mdLogo} {
    /* Prevent the initial menu height flash on desktop */
    max-height: ${(props) => props.secondaryNavOpen ? undefined : '108px'};
  }
  z-index: 9999;
  border-radius: ${(props) => props.theme.config.componentRadius};
  border-bottom-left-radius: ${(props) =>
    props.secondaryNavOpen ? '0' : undefined};
  border-bottom-right-radius: ${(props) =>
    props.secondaryNavOpen ? '0' : undefined};
  position: fixed;
  top: ${(props) => (props.open ? '0' : '0.25rem')};
  left: ${(props) => (props.open ? '0' : '0.25rem')};
  right: ${(props) => (props.open ? '0' : '0.25rem')};
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  ${mediaQueries.mdLogo} {
    margin: 2rem;
    top: 0;
    left: 0;
    right: 0;
  }
  .header-wrapper {
    padding: 0 1rem 0.625rem;
    padding-top: ${(props) => (props.open ? '0.875rem' : '0.625rem')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    ${mediaQueries.mdLogo} {
      ${grid}
      row-gap: 0;
      grid-template-columns: repeat(12, 1fr);
      padding: 1rem;
      z-index: 2;
      background-color: ${colors.white};
      border-radius: ${(props) => props.theme.config.componentRadius};
      border-bottom-left-radius: ${(props) =>
        props.secondaryNavOpen ? '0' : undefined};
      border-bottom-right-radius: ${(props) =>
        props.secondaryNavOpen ? '0' : undefined};
    }
    ${mediaQueries.mdLogo} {
      /* need to repeat the query  so it is applied without using !important */
      /* otherwise it takes the value of the grid styling, which we want to override */
      gap: 0rem;
    }
    ${mediaQueries.xl} {
      padding: 1rem 1.5rem 1rem 2rem;
    }
    .header-logo-wrapper {
      display: flex;
      position: relative;
      .logo {
        display: block;
      }

      ${mediaQueries.mdLogo} {
        display: block;
        grid-column: 1 / span 1;
        margin: 0;
        margin-right: 0.25rem;
        position: initial;
        top: initial;
        left: initial;
        transform: none;
      }
      ${mediaQueries.lgLogo} {
        margin-right: 1rem;
      }
      path {
        fill: ${(props) => (props.theme.key === 'zsl' && props.open ? colors.white : props.theme.colors.primary)};
        ${mediaQueries.mdLogo} {
          fill: ${(props) => props.theme.colors.primary};
        }
      }
    }
    .header-button {
      line-height: normal;
      padding: 0.875rem 0.5rem 0.75rem;
      ${mediaQueries.mdLogo} {
        grid-column: 12 / span 1;
        font-size: ${(props) => (props.theme.key !== 'zsl' ? '1rem' : undefined )};
        margin-left: 0.9375rem;
        min-width: 54px;
        padding: 0.875rem 1rem 0.75rem;
      }
      ${mediaQueries.lgLogo} {
        font-size: 1.125rem;
      }
      background-color: ${(props) =>
        props.open && props.isTablet
          ? props.theme.key === 'zsl'
            ? colors.zooBg
            : props.theme.colors.button.primary.bg
          : undefined};
      color: ${(props) =>
        props.open && props.isTablet
          ? props.theme.key === 'zsl'
            ? props.theme.colors.primary
            : undefined
          : undefined};
    }
  }
  background-color: ${(props) =>
    props.open
      ? props.theme.key === 'zsl'
        ? props.theme.colors.primary
        : props.theme.colors.background
      : colors.white};
  ${mediaQueries.mdLogo} {
    background-color: ${colors.white};
  }

  .header-gradient {
    position: fixed;
    width: 100%;
    height: 11.25rem;
    top: 0;
    left: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.48), rgba(217, 217, 217, 0));
    z-index: 1;
  }
`;

export const StyledHamburger = styled('button')<{ open: boolean }>`
  ${mediaQueries.mdLogo} {
    display: none;
  }
  padding: 0;
  border: none;
  background: none;
  margin: 0;
  .hamburger-button {
    > .hamburger-symbol {
      display: ${(props) => (props.open ? 'none' : 'block')};
      background-color: ${(props) =>  props.theme.colors.button.primary.bg};
      /* border-radius taken from design, doesn't match config */
      border-radius: ${(props) => (props.theme.key === 'zsl' ? '5px' : '12px')};
      path {
        stroke: ${(props) => (props.theme.key !== 'zls' ? colors.black : undefined)};
      }
    }
    > .cross-symbol {
      display: ${(props) => (props.open ? 'block' : 'none')};
      /* border-radius taken from design, doesn't match config */
      border-radius: ${(props) => (props.theme.key === 'zsl' ? '5px' : '12px')};
      g rect {
        fill: ${(props) => (props.theme.key !== 'zsl' ? props.theme.colors.button.primary.bg : undefined)};
      }
      g path {
        stroke: ${(props) => (props.theme.key !== 'zsl' ? colors.black : undefined)};
      }
      rect {
        stroke: ${(props) => (props.theme.key !== 'zsl' ? props.theme.colors.button.primary.bg : undefined)};
      }
    }
  }
`;
